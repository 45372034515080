import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { SongConsumer } from './context';
import AudioControl from './audio-control';
import '../assets/styles/components/header.scss';

const AudioPlayer = () => {
  const displayAudioPlayer = (descriptiveLine, context) => {
    const { state } = context;
    if (state.name) {
      return (
        <>
          <strong>Now Playing:</strong>
          <AudioControl edition={state} context={context} />
          <br />
          {state.name}
          :&nbsp;
          <em>{state.title}</em>
        </>
      );
    }

    return descriptiveLine;
  };

  return (
    <StaticQuery
      query={graphql`
      query PlayerQuery {
          datoCmsHeader {
              descriptiveLine
          }
      }
      `}
      render={(data) => (
        <SongConsumer>
          {(context) => (
            <>
              {displayAudioPlayer(data.datoCmsHeader.descriptiveLine, context)}
            </>
          )}
        </SongConsumer>
      )}
    />
  );
};

export default AudioPlayer;
