import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Header from './header';
import Footer from './footer';
import Acknowledgement from './acknowledgement';
import '../static/fonts.css';
import '../assets/styles/base/scaffolding.scss';

const TemplateWrapper = (
  {
    showTitleRow,
    pagination,
    paginate,
    children,
    hideFooter,
    pageClass,
  },
) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      datoCmsHome {
      seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
      }
    }
  `);
  const [showSubscribe, setShowSubscribe] = useState(false);

  return (
    <div
      className={pageClass}
      role="button"
      tabIndex={0}
    >
      {data.datoCmsHome.seoMetaTags.title}
      <Acknowledgement />
      <Header
        showTitleRow={showTitleRow}
        pagination={pagination}
        paginate={paginate}
        showSubscribe={showSubscribe}
        setShowSubscribe={setShowSubscribe}
      />
      <main>{children}</main>
      {hideFooter && (
        <Footer />
      )}
    </div>
  );
};

TemplateWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  pagination: PropTypes.shape(),
  showTitleRow: PropTypes.string,
  paginate: PropTypes.string,
  pageClass: PropTypes.string,
  hideFooter: PropTypes.bool,
};

TemplateWrapper.defaultProps = {
  showTitleRow: '',
  pagination: null,
  paginate: '',
  pageClass: '',
  hideFooter: true,
};

export default TemplateWrapper;
