import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Nav from './navigation';
import AudioPlayer from './audio-player';
import '../assets/styles/components/header.scss';

const Header = ({
  showTitleRow,
  pagination,
  paginate,
  showSubscribe,
  setShowSubscribe,
}) => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <StaticQuery
      query={graphql`
        query HeaderQuery {
          datoCmsHeader {
              logoText
              descriptiveLine
              logo {
                fixed(height: 23, imgixParams: { auto: "compress" }) {
                    ...GatsbyDatoCmsFixed
                }
              }
          }
          allDatoCmsAction {
            edges {
              node {
                id
              }
            }
          }
        }
      `}
      render={(data) => (
        <header className={`container ${showMenu ? 'is-open' : ''}`}>
          <div className="top-row-wrapper">
            <div className="top-row row">
              <div className="three-quarters">
                <Link to="/" className="logo-text-wrapper">
                  {data.datoCmsHeader.logoText}
                </Link>
                <button
                  className="hamburger hamburger--squeeze"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowMenu(!showMenu);
                  }}
                >
                  <span className="hamburger-box">
                    <span className="hamburger-inner" />
                  </span>
                </button>
                <Nav
                  showSubscribe={showSubscribe}
                  setShowSubscribe={setShowSubscribe}
                  showActions={data.allDatoCmsAction.edges.length >= 1}
                />
              </div>
              <div className="one-quarter hidden-xs">
                <Link to="/" className="logo-wrapper">
                  <Img fixed={data.datoCmsHeader.logo.fixed} className="logo" />
                </Link>
              </div>
            </div>
            <div className="bottom-row row">
              {pagination === null && (
                <div className={`three-quarters header-description-wrapper ${showTitleRow ? '' : 'no-border'}`}>
                  <h1 className="header-description">{showTitleRow}</h1>
                </div>
              )}
              {pagination && (
                <>
                  <div className="one-quarter no-border" />
                  <div className="two-quarters pagination">
                    {pagination.prev && (
                      <Link to={`/${paginate}/${pagination.prev.slug}`}>Previous</Link>
                    )}
                    {pagination.prev && pagination.next && (
                      <span className="pagination-divider">
                        /
                      </span>
                    )}
                    {pagination.next && (
                      <Link to={`/${paginate}/${pagination.next.slug}`} className="next-link">Next</Link>
                    )}
                  </div>
                </>
              )}
              <div className="one-quarter">
                <div className="header-description">
                  <AudioPlayer />
                </div>
              </div>
            </div>
          </div>
        </header>
      )}
    />
  );
};

Header.propTypes = {
  showTitleRow: PropTypes.string,
  pagination: PropTypes.shape(),
  paginate: PropTypes.string,
  showSubscribe: PropTypes.bool.isRequired,
  setShowSubscribe: PropTypes.func.isRequired,
};
Header.defaultProps = {
  showTitleRow: '',
  pagination: null,
  paginate: '',
};

export default Header;
