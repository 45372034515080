import React from 'react';
import '../assets/styles/components/footer.scss';

const Footer = () => (
  <footer className="footer">
    <div className="container">
      <div className="footer-container row">
        <div className="one-quarter" />
        <div className="three-quarters">
          © Longform Editions&nbsp;
          {new Date().getFullYear()}
          .
          {' '}
          Design by
          {' '}
          <a href="https://www.formist.co/" target="_blank" rel="noopener noreferrer">Formist</a>
          .
          {' '}
          Built by
          {' '}
          <a href="https://srrycmpny.com/" target="_blank" rel="noopener noreferrer">Sorry Company</a>
          .
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
