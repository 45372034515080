import React from 'react';
import PropTypes from 'prop-types';
import Pause from '../images/pause.inline.svg';
import Play from '../images/play.inline.svg';

const AudioControl = ({ edition, context }) => {
  const isTrackSelected = () => {
    if (context.state.trackId === edition.trackId) {
      return context.state.play;
    }

    return false;
  };

  const playPauseSong = () => {
    let newEdition = true;

    if (isTrackSelected(context, edition.id)) {
      newEdition = false;
    }

    context.setCurrentPlaying({ ...edition, play: newEdition });
  };

  return (
    <>
      <button
        type="button"
        className="audio-control"
        onClick={(e) => {
          e.preventDefault();
          playPauseSong();
        }}
      >
        {isTrackSelected() && (
          <>
            <Pause />
            Stop
          </>
        )}
        {!isTrackSelected() && (
          <>
            <Play />
            Play
          </>
        )}
      </button>
    </>
  );
};

AudioControl.propTypes = {
  edition: PropTypes.shape().isRequired,
  context: PropTypes.shape().isRequired,
};

export default AudioControl;
