import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import '../assets/styles/components/acknowledgement.scss';
/* global window */
const cookieName = 'acknowledgementModel';
const getCookie = (cname) => {
  if (typeof window !== 'undefined') {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(window.document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  }
  return '';
};

const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = `expires=${d.toUTCString()}`;

  if (typeof window !== 'undefined') {
    window.document.cookie = `${cname}=${cvalue};${expires};path=/`;
  }
};

const Acknowledgement = () => {
  const gatsbyRepoData = useStaticQuery(graphql`
    query {
        datoCmsAcknowledgement {
            text
            closeButton {
              fixed(height: 30, width: 30, imgixParams: { auto: "compress" }) {
                  ...GatsbyDatoCmsFixed
              }
            }
            timeout
        }
      }
  `);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const cookie = getCookie(cookieName);
      if (cookie === '') {
        const $body = window.document.body;
        const { timeout } = gatsbyRepoData.datoCmsAcknowledgement;
        $body.classList.add('open-acknowledgement');

        const timeoutFunc = () => {
          if ($body.classList.contains('open-acknowledgement')) {
            $body.classList.remove('open-acknowledgement');
          }
        };

        setTimeout(timeoutFunc, parseInt(timeout, 10));
        setCookie(cookieName, 'true', 75);
      }
    }
  }, [gatsbyRepoData]);

  const closeAcknowledgement = () => {
    if (typeof window !== 'undefined') {
      window.document.body.classList.remove('open-acknowledgement');
    }
  };

  return (
    <div
      className="acknowledgement-wrapper"
      role="button"
      tabIndex={0}
      onClick={(e) => {
        e.preventDefault();
        closeAcknowledgement();
      }}
      onKeyDown={closeAcknowledgement()}
    >
      <div className="close-btn" role="button">
        <Img
          fixed={gatsbyRepoData.datoCmsAcknowledgement.closeButton.fixed}
          alt="close-button"
        />
      </div>
      <h2>
        {gatsbyRepoData.datoCmsAcknowledgement.text}
      </h2>
    </div>
  );
};

export default Acknowledgement;
