import React, { useEffect } from 'react';
import '../assets/styles/components/subscribe-form.scss';
import Arrow from '../images/subscribe-arrow.inline.svg';

const BrevoForm = ({ formURL }) => {
  const captchaScriptId = 'recaptcha-script';
  const mainScriptId = 'main-script';

  const loadScript = (scriptId, src) => {
    let script = document.getElementById(scriptId);
    if (!script) {
      script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.defer = true;
      script.id = scriptId;
      document.body.appendChild(script);
    } else {
      // Update the script src to force reload if it's already present
      script.src = src;
    }
  };

  const unloadScript = (scriptId) => {
    const script = document.getElementById(scriptId);
    if (script) {
      script.remove();
    }
  };

  const loadRecaptchaScript = () => {
    const src = `https://www.google.com/recaptcha/api.js`;
    loadScript(captchaScriptId, src);
  };

  const loadMainScript = () => {
    const src = `https://sibforms.com/forms/end-form/build/main.js`;
    loadScript(mainScriptId, src);
  };

  useEffect(() => {
    loadRecaptchaScript();
    loadMainScript();

    return () => {
      unloadScript(captchaScriptId);
      unloadScript(mainScriptId);
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const data = new FormData(form);

    try {
      const response = await fetch(formURL, {
        method: 'POST',
        body: data,
        headers: {
          'Accept': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log('Success:', result);
    } catch (error) {
      console.error('Error:', error);
      // Handle the case where response is not JSON
      try {
        const textResponse = await error.text();
        console.log('Text response:', textResponse);
      } catch (e) {
        console.error('Could not parse response:', e);
      }
    }
  };

  return (
    <>
      <p>
        Subscribe to Longform Editions to receive updates on editions and events.
      </p>
      <form id="sib-form" method="POST" action={formURL} data-type="subscription" onSubmit={handleSubmit}>
        <div style={{ padding: '8px 0' }}>
          <div className="sib-input sib-form-block">
            <div className="form__entry entry_block">
              <div className="form__label-row">
                <div className="entry__field">
                  <input
                    className="input"
                    type="email"
                    id="EMAIL"
                    name="EMAIL"
                    autoComplete="off"
                    placeholder="Enter email address"
                    data-required="true"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="sib-form-block">
            <button className="sib-form-block__button sib-form-block__button-with-loader"
              form="sib-form"
              type="submit">
              <Arrow />
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default BrevoForm;
